import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Section } from '../models/section';
import { Observable } from "rxjs/Observable";
import { Student } from '../models/student';
import { isNodeSdk } from "@firebase/util";
import { Area } from '../models/area';
import { AreaItem } from "../models/area-item";

@Injectable()
export class MigrationService {

    constructor(private db: AngularFirestore) {
        // const settings = { timestampsInSnapshots: true };
        // db.firestore.settings(settings);
    }

    /*
      14.05.2024: Cada año se debe correr el proceso en orden:
      -  createSection (crea las secciones)
      -  createStudents (migra los estudiantes al siguiente año)
      -  creteReportCards (crea las libretas)
      -  createReportAreas (copia las libretas del año anterior en la nueva)
    */
    migrateProcess(year: number) {

        // this.createSection(2, 'Semillitas - 2 años', 'child_friendly', year, 'Miss Priscila');
        //2024: 14 de mayo de 2023
        // this.createSection(3, 'Sonrisas - 3 años', 'child_care', year, 'Miss Marissa');
        // this.createSection(4, 'Amistad - 4 años', 'directions_run', year, 'Miss Priscila');
        // this.createSection(5, 'Alegría - 5 años', 'local_library', year, 'Miss Mery');

        // this.createStudents(`${year-1}-2`, `${year}-3`);
        // this.createStudents(`${year-1}-3`, `${year}-4`);
        // this.createStudents(`${year-1}-4`, `${year}-5`);

        //  this.createReportCards(year, 3);
        //  this.createReportCards(year, 4);
        //  this.createReportCards(year, 5);

        //this.createReportAreas(2018, 2019, 2);
         this.createReportAreas(year - 1, year, 3);
         this.createReportAreas(year - 1, year, 4);
         this.createReportAreas(year - 1, year, 5);

        alert("Process completed");
    }

    createSection(section: number, sectionName: string, icon: string, year: number, teacher: string) {
        var sectionCollection = this.db.collection<Section>('sections');

        sectionCollection.doc(year.toString() + '-' + section.toString()).set({
            name: sectionName,
            icon: icon,
            teacher: teacher,
            year: year
        });
    }

    createStudents(sectionOriginal: string, sectionNew: string) {
        var studentCollection = this.db.collection<Student>('sections/' + sectionOriginal + '/students');

        var newStudentCollection = this.db.collection<Student>('sections/' + sectionNew + '/students');

        // studentCollection.snapshotChanges().forEach(a => {
        //     console.log(a);

        //     a.forEach(b => {
        //         console.log(b);
        //         b.payload.doc.data()
        //     })
        // })

        studentCollection.ref.get().then(a => {
            a.docs.forEach(function (value, index, data) {
                let info = value.data();

                newStudentCollection.add({
                    name: info.name,
                    fatherName: info.fatherName,
                    motherName: info.motherName
                });
            })
        })
    }

    //Crear los bimestres de cada ño
    createReportCards(year: number, section: number) {

        let sectionName = year + '-' + section;
        var studentCollection = this.db.collection('sections/' + sectionName + '/templates');

        studentCollection.doc('first').set({ icon: 'looks_one', name: 'Primer Bimestre ' + year });
        studentCollection.doc('second').set({ icon: 'looks_two', name: 'Segundo Bimestre ' + year });
        studentCollection.doc('third').set({ icon: 'looks_3', name: 'Tercer Bimestre ' + year });
        studentCollection.doc('fourth').set({ icon: 'looks_4', name: 'Cuarto Bimestre ' + year });
    }

    ///Copia los items del año anterior en el actual
    createReportAreas(lastYear: number, currentYear: number, section: number) {
        let lastSection = lastYear + '-' + section;
        let currentSection = currentYear + '-' + section;

        let templates = ['first', 'second', 'third', 'fourth'];

        templates.forEach( template => {

            var lastAreaCollection = this.db.collection<Area>('sections/' + lastSection + '/templates/' + template + '/areas');
            var currentAreaCollection = this.db.collection<Area>('sections/' + currentSection + '/templates/' + template + '/areas');

            lastAreaCollection.ref.get().then(a => {
                a.docs.forEach(value => {
                    let info = value.data();

                    var originalItemsCollection = this.db.collection<AreaItem>('sections/' + lastSection + '/templates/' + template + '/areas/' + value.id + '/items');

                    currentAreaCollection.add({
                        name: info.name,
                        order: info.order,
                        date: info.date,
                        createdBy: '',
                        modifiedBy: ''
                    }).then(valueRef => {
                        var currentItemsCollection = this.db.collection<AreaItem>('sections/' + currentSection + '/templates/' + template + '/areas/' + valueRef.id + '/items');

                        originalItemsCollection.ref.get().then(c => {
                            c.docs.forEach(cValue => {
                                let cInfo = cValue.data();

                                currentItemsCollection.add({
                                  date: cInfo.date,
                                  name: cInfo.name,
                                  order: cInfo.order,
                                  createdBy: cInfo.createdBy,
                                  modifiedBy: null
                                });
                            })
                        })

                    });

                })
            });

        })
    }

    createReportItems(lastYear: number, currentYear: number, section: number) {

        let lastSection = lastYear + '-' + section;
        let currentSection = currentYear + '-' + section;

        let templates = ['first', 'second', 'third', 'fourth'];

        templates.forEach( template => {

            var lastAreaCollection = this.db.collection<Area>('sections/' + lastSection + '/templates/' + template + '/areas');

            lastAreaCollection.ref.get().then(a => {
                a.docs.forEach(value => {
                    let info = value.data();

                    var originalItems = this.db.collection<AreaItem>('sections/' + lastSection + '/templates/' + template + '/areas/' + value.id);
                    var currentItems = this.db.collection<AreaItem>('sections/' + currentSection + '/templates/' + template + '/areas/' + value.id);

                    originalItems.ref.get().then(b => {
                        b.docs.forEach(bValue => {
                            let itemInfo = bValue.data();

                        })

                    });

                })
            });


        });


    }

}
