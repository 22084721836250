import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-restablecer-contrasena',
  templateUrl: './restablecer-contrasena.component.html',
  styleUrls: ['./restablecer-contrasena.component.css']
})
export class RestablecerContrasenaComponent implements OnInit {

  emailRestablecer: string;
  error: string;
  exito: boolean = false;

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

  async enviar() {
    try {
      this.error = '';
      this.exito = false;
      await this.authService.enviarLink(this.emailRestablecer);

      this.exito = true;
    }
    catch(err) {
      console.log('err:', err.message);
      this.error = err.message;
    }
  }
}
