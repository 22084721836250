import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  imports: [MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatSelectModule,
    MatInputModule, MatSnackBarModule, MatGridListModule, MatMenuModule,
    MatIconModule, MatSidenavModule, MatToolbarModule, MatListModule, MatCardModule,
    MatDialogModule, MatDatepickerModule, MatTooltipModule, MatRadioModule,
   ],
  exports: [MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatSelectModule,
    MatInputModule, MatSnackBarModule, MatGridListModule, MatMenuModule,
    MatIconModule, MatSidenavModule, MatToolbarModule, MatListModule, MatCardModule,
    MatDialogModule, MatDatepickerModule, MatTooltipModule, MatRadioModule],
})
export class MaterialModule { }
