import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Section } from '../models/section';
import { Report } from '../models/report';
import { Observable } from 'rxjs/Observable';
import { Area } from '../models/area';
import { AreaFormComponent } from '../area-form/area-form.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaItemFormComponent } from '../area-item-form/area-item-form.component';
import { Comment } from '../models/comment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.css']
})
export class ReportDetailComponent implements OnInit {

  @Input() report: Report;
  reportDoc: AngularFirestoreDocument<Report>;
  report$: Observable<Report>;
  listOfAreas$: Observable<any[]>
  reportId: string = '';
  sectionId: string = '';
  studentId: string = '';
  comment: Comment;

  constructor(private route: ActivatedRoute,
    private db: AngularFirestore,
    private matDialog: MatDialog) { }

  ngOnInit() {
    console.log('we are in details report');
    this.comment = new Comment();
    this.getDetails();
  }

  getDetails() {

    this.route.paramMap.subscribe(p => {

      this.reportId = p.get('repId');
      this.sectionId = p.get('secId');
      this.studentId = p.get('studentId');

      this.reportDoc = this.db.doc<Report>('sections/' +  this.sectionId + '/templates/' + this.reportId);
      this.report$ = this.reportDoc.valueChanges(); //this is an observer, the variable report$ is waiting.

      this.listOfAreas$ = this.reportDoc.collection<Area>('areas', ref => {
        return ref.orderBy("date", "asc")
      })
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
              const data = a.payload.doc.data() as Report
              const id = a.payload.doc.id;
              return { id, data }
          })
        })
      )


      this.db.doc<Comment>('comments/' + this.sectionId + '-' + this.reportId  + '-' + this.studentId)
      .valueChanges()
      .subscribe(a => {
        this.comment = a as Comment
      });

    })
  }

  newArea() {
    let dialogRef = this.matDialog.open(AreaFormComponent, {
      width: '600px',
      data: { reportId: this.reportId, areaId: '', sectionId: this.sectionId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("after closed event");
    });
  }

  updateArea(areaId) {
    let dialogRef = this.matDialog.open(AreaFormComponent, {
      width: '600px',
      data: { reportId: this.reportId, areaId: areaId, sectionId: this.sectionId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("after closed event");
    });
  }

  deleteArea(areaId) {
    if (confirm('¿Está seguro de eliminar el área y todos sus items?'))
    {
      this.db.doc<Area>('sections/' + this.sectionId + '/templates/' + this.reportId + '/areas/' + areaId)
      .delete()
      .then(a => {
        alert('El área se eliminó con éxito');
      })
    }
  }

  newItem(areaId)
  {
    console.log(areaId);
    let dialogRef = this.matDialog.open(AreaItemFormComponent, {
      width: '600px',
      data: { reportId: this.reportId, areaId: areaId, sectionId: this.sectionId, areaItemId: '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("after closed event");
    });
  }



}
