<mat-card>
  <mat-card-content>
    <h5>Control de asistencia - <span style="background-color:yellow">{{ now | date:'dd/MMM - hh:mm:ss aa'}}</span></h5>
    <mat-form-field>
      <input id="txtDNI" style="width:100px" (keyup.enter)="searchPerson(dni.value)" type="number" matInput value="" maxlength="8" placeholder="ingresar DNI" #dni>
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="searchPerson(dni.value)">Buscar</button>
    <button mat-raised-button color="basic" (click)="clean()">Limpiar</button>
    <input id="hddStaffId" type="hidden" value="{{ selectedDNI }}">
    <mat-form-field>
      <input matInput style="width:100px" disabled style="font-size:1.3em; background-color:yellow" value="{{ fullName }}">
    </mat-form-field>
    <button class="btnAction" mat-raised-button color="primary" (click)="saveAssist('i')">Ingreso</button>
      <button class="btnAction" mat-raised-button color="accent" (click)="saveAssist('s')">Salida</button>    
    <mat-nav-list dense>
      <mat-list-item *ngFor="let assist of assistList$ | async">
        <span>{{ assist.fullname}}</span>
        <span class="example-spacer"></span>    
        <span>{{ assist.time | date: 'hh:mm:ss aaa' }}</span>
        <span class="example-spacer"></span>        
        <button *ngIf="assist.type == 'i'" mat-button color="primary">Ingreso</button>
        <button *ngIf="assist.type == 's'" mat-button color="accent">Salida</button>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-nav-list>
  </mat-card-content>
</mat-card>