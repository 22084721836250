
  <div *ngIf="authService.user | async">
    <mat-sidenav-container class="app-container">
      <mat-sidenav #sidenav mode="push" closed style="width: 250px;">
        <mat-nav-list>
          <div (click)="sidenav.close(); goTo('');" matSubheader>Rosymarti {{ year }}</div>
          <!-- <mat-list-item (click)=" sidenav.close(); goTo('seccion/2019-2');">
            <mat-icon mat-list-icon>child_friendly</mat-icon>
            <p mat-line>2 años</p>
          </mat-list-item> -->
          <mat-list-item (click)=" sidenav.close(); goTo('seccion/' + year + '-3');">
            <mat-icon matListItemIcon>child_care</mat-icon>
            <div matListItemTitle>3 años - Sonrisas</div>
          </mat-list-item>
          <mat-list-item (click)=" sidenav.close(); goTo('seccion/' + year + '-4');">
            <mat-icon matListItemIcon>directions_run</mat-icon>
            <div matListItemTitle>4 años - Amistad</div>
          </mat-list-item>
          <mat-list-item (click)=" sidenav.close(); goTo('seccion/' + year + '-5');">
            <mat-icon matListItemIcon>local_library</mat-icon>
            <div matListItemTitle>5 años - Alegría</div>
          </mat-list-item>
          <!-- <mat-list-item (click)=" sidenav.close(); goTo('teacher-assist');">
            <p mat-line style="font-size:8pt;">Control de asistencia</p>
          </mat-list-item> -->
          <!-- <mat-list-item (click)=" sidenav.close(); createNewYear(2024);">
            <mat-icon matListItemIcon>add_box</mat-icon>
            <div matListItemTitle>Create 2024</div>
          </mat-list-item> -->
          <mat-divider></mat-divider>
          <mat-list-item  (click)="logout()">
              <mat-icon matListItemIcon>power_settings_new</mat-icon>
              <div matListItemLine>Cerrar sesión</div>
            </mat-list-item>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <mat-toolbar>
          <button mat-mini-fab color="primary" (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
          <span style="flex:0.5"></span>
          <span id="spanTitle">Rosymarti App {{ year }}</span>
          <!-- <span style="flex:0.5"></span>
          <button mat-raised-button color="primary" (click)="logout()" *ngIf="authService.user | async">Cerrar sesión</button> -->
        </mat-toolbar>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>

  <div *ngIf="!(authService.user | async)" class="login-container">
    <form #myForm="ngForm" (ngSubmit)="login()">
      <mat-card class="loginCard">
        <mat-card-content>
            <img  style="max-height:250px;" src="https://rosymartistorage.blob.core.windows.net/webpage/LogoCompletoTransparente.webp" />
            <br><br>
            <h1 class="login-title">Aplicación en línea</h1>
          <mat-form-field>
            <input #myemail="ngModel" matInput type="email" placeholder="Correo" name="email" [(ngModel)]="email" required>
           <div *ngIf="myemail.errors?.required && myemail.touched" class="error">
             El correo es obligatorio
           </div>
          </mat-form-field>
          <br>
          <mat-form-field>
            <input #mypassword="ngModel" matInput type="password" placeholder="Contraseña" name="password" [(ngModel)]="password" required>
            <div *ngIf="mypassword.errors?.required && mypassword.touched" class="error">
              La contraseña es obligatoria
            </div>
          </mat-form-field>

          <!-- <input type="text" [(ngModel)]="email" placeholder="usuario">
          <input type="password" [(ngModel)]="password" placeholder="contraseña"> -->
        </mat-card-content>
        <mat-card-actions style="justify-content: center;" align="center">
          <button mat-raised-button color="primary" type="submit" [disabled]="!myForm.valid" (click)="login()">Iniciar sesión</button>
          <div class="error" *ngIf="error">
            {{ error }}
          </div>

        </mat-card-actions>
      </mat-card>
    </form>
    <div style="margin-top:30px">
      <button mat-raised-button (click)="restablecer()">Restablecer contraseña</button>
    </div>
  </div>


