import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Section } from '../models/section';
import { Observable } from "rxjs/Observable";
import { map  } from 'rxjs/operators';
@Injectable()
export class SectionService {

    private sectionCollection: AngularFirestoreCollection<Section>;
    listOfSections: Observable<Section[]>;

    constructor(private db: AngularFirestore) {
        // const settings = { timestampsInSnapshots: true};
        // db.firestore.settings(settings);
    }

    /*Get all sections filtered by year */
    getByYear() {

        this.sectionCollection = this.db.collection<Section>('sections',
            ref => {
                return ref.orderBy("order", "asc")
            }
        );

        return this.sectionCollection
        .snapshotChanges()
        .pipe(
          map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data() as Section
                const id = a.payload.doc.id;
                return { id, data }
            })
        })
        )

    }
}
