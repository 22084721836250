<!-- <mat-toolbar color="primary">
  <mat-toolbar-row>
    <span *ngIf="(report$ | async) as report; else loading">
      Libreta de notas: {{ report.name }} {{ report.order }}
    </span>
    <span class="example-spacer"></span>
  </mat-toolbar-row>
</mat-toolbar> -->

<mat-card>
  <mat-card-header *ngIf="(report$ | async) as report; else loading">
    <mat-card-title>Configuración de Libreta</mat-card-title>
    <mat-card-subtitle style="font-size:1em">{{ report.name }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <!-- <h5>Áreas</h5>  -->
    <button mat-raised-button color="primary" (click)="newArea()">
      <mat-icon>add</mat-icon>Nueva área
    </button>
    <br><br>
    <div *ngFor="let area of listOfAreas$ | async">
      <span style="cursor: pointer;" (click)="updateArea(area.id)">{{ area.data.name }} </span>
      <button mat-mini-fab color="primary" (click)="newItem(area.id)" title="Agregar item">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-mini-fab color="warn" (click)="deleteArea(area.id)" title="Eliminar item">
        <mat-icon>delete</mat-icon>
      </button>

      <app-area-item [areaId]="area.id"></app-area-item>
    </div>

  </mat-card-content>
</mat-card>


<ng-template #loading>
  Cargando ...
</ng-template>
