<!--<div *ngIf="(value$ | async) as value2; else loading">
  <input value= "{{ value2 }}" type="input" style="width: 50px;">
</div>-->
<!-- <div *ngIf="(scoreValue$ | async) as info; else loading">
  {{ info.value }}
  
</div> -->

<input [(ngModel)]="value" name="value" value="{{value}}" type="input" (blur)="saveValue()" style="width: 85px; text-align: center;">

<!-- <ng-template #loading>
  Cargando nota ...
</ng-template> -->