import { Component } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Student } from './models/student';
import { Section } from './models/section';
import { SectionService } from './services/section.service';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { MigrationService } from './services/migration.service';
import { RestablecerContrasenaComponent } from './restablecer-contrasena/restablecer-contrasena.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  listOfSections: Observable<any[]>;
  email: string;
  password: string;
  error: string;
  year: number = 2024;

  constructor(private sectionService: SectionService,
    private router: Router, public authService: AuthService,
    private matDialog: MatDialog,
    private migrationService: MigrationService) {

    // const settings = { timestampsInSnapshots: true};
    // db.firestore.settings(settings);

    this.listOfSections = this.sectionService.getByYear();
  }

  onSelectSection(section: Section) {
    //console.log(section);
  }

  goTo(link: string) {

    //console.log(link);
    this.router.navigate([link]);

  }

  signup() {
    this.authService.signup(this.email, this.password);
    this.email = this.password = '';
  }

  async login() {
    //destructuring the information. creating two new varibles for fun :)
    const { e, p}  = { e: this.email, p: this.password };

    try {
      await this.authService.login(e, p);
    }
    //this is a promise
    catch(err) {
      this.error = err.message;
    }

    //this.email = this.password = '';
  }

  logout() {
    this.authService.logout();
  }

  restablecer() {

    let dialogRef = this.matDialog.open(RestablecerContrasenaComponent, {
      width: '600px',
      data: {  }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("this is always showed");
    });
  }

  createNewYear(year: number) {
    //console.log(year);
    //this.migrationService.migrateProcess(year);
  }
}
