import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Student } from '../models/student';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-new-student',
  templateUrl: './new-student.component.html',
  styleUrls: ['./new-student.component.css']
})
export class NewStudentComponent implements OnInit {

  sectionId: string = '';
  studentId: string = '';
  student: Student;

  constructor(
    public dialogRef: MatDialogRef<NewStudentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private db: AngularFirestore
  ) {
    this.sectionId = data.sectionId;
    this.studentId = data.studentId;

  }

  ngOnInit() {
    console.log(this.studentId);
    if (this.studentId == '') {
      this.student = new Student();
    }
    else
    {
      this.db.doc<Student>('sections/' + this.sectionId + '/students/' + this.studentId)
        .valueChanges()
        .subscribe(a => {
          this.student = a;
        });
    }
  }

  save() {

    if (this.studentId == '') {
      this.db.collection<Student>('sections/' + this.sectionId + '/students')
        .add({
          name: this.student.name,
          fatherName: this.student.fatherName,
          motherName: this.student.motherName
        });
    }
    else {
      this.db.doc<Student>('sections/' + this.sectionId + '/students/' + this.studentId)
        .update({
          name: this.student.name,
          fatherName: this.student.fatherName,
          motherName: this.student.motherName
        });

    }
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(true);
  }

}
