import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestoreDocument, AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Section } from '../models/section';
import { Observable } from 'rxjs/Observable';
import { Report } from '../models/report';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  sectionDoc: AngularFirestoreDocument<Section>;
  listOfReports$: Observable<any[]>
  selectedReport: Report;

  constructor(private route: ActivatedRoute, private db: AngularFirestore) { }

  ngOnInit() {
    this.getReports();
  }

  onSelect(report: Report)
  {
    this.selectedReport = report;
  }

  getReports() {
    this.route.paramMap.subscribe(p => {

      let sectionId = p.get('secId');
      this.sectionDoc = this.db.doc<Section>('sections/' + sectionId);

      this.listOfReports$ = this.sectionDoc.collection<Report>('reports', ref => {
        return ref.orderBy("order", "asc")
      })
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
              const data = a.payload.doc.data() as Report
              const id = a.payload.doc.id;
              return { id, data }
          })
      })
      )

    })
  }

}
