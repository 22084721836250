import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/compat/firestore";
import { AssistControl } from '../models/assistcontrol';
import { map } from 'rxjs/operators';

@Injectable()
export class StaffService
{
    private staffCollection: AngularFirestoreCollection<any>;

    constructor(private db: AngularFirestore) {
        // const settings = { timestampsInSnapshots: true};
        // db.firestore.settings(settings);
    }

    getByDNI(dni: string) {
        return this.db.doc('staff/' + dni).valueChanges();
    }

    getStaff() {

        this.staffCollection = this.db.collection('staff',
            ref => {
                return ref.orderBy("name", "asc")
            }
        );

        return this.staffCollection
        .snapshotChanges()
        .pipe(
          map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data()
                const id = a.payload.doc.id;
                return { id, data }
            })
        })
        )

    }

    saveAssit(dni: string, fullname: string, type: string)
    {
        return this.db.collection<AssistControl>('assistControl')
        .add({
            dni: dni,
            fullname: fullname,
            time: Date.now(),
            createdDate: new Date(),
            type: type
        });

    }

    getAssistList() {

        let day = new Date().getDate();
        let month = new Date().getMonth();
        let year     = new Date().getFullYear();

        let startDate = new Date(year, month, day, 0, 0, 0);
        let endDate =  new Date(year, month, day, 23, 59, 59);

        return this.db.collection('assistControl',
            ref => {
                return ref.where('createdDate', '>=', startDate)
                        .where('createdDate', '<=', endDate)
                        .orderBy("createdDate", "asc")
            }
        ).valueChanges();


    }
}
