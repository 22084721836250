import { Component, OnInit } from '@angular/core';
import { DownloadService } from '../services/download.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {

  constructor(private downloadService: DownloadService) {

   }

  ngOnInit() {
    
  }

}
