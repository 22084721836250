<mat-card>
  <mat-card-content>
    <div *ngIf="section; else loading">      
      <h6><mat-icon style="font-size:10pt;">{{section.icon}}</mat-icon> Profesora: {{ section.teacher }}</h6>
      <a routerLink="/seccion/{{sectionId}}/alumnos">Lista de Alumnos</a>
      <br>
      <a routerLink="/seccion/{{sectionId}}/libreta/first">Configuración de libreta de notas 1er Bimestre</a>
      <br>
      <a routerLink="/seccion/{{sectionId}}/libreta/second">Configuración de libreta de notas 2do Bimestre</a>
      <br>
      <a routerLink="/seccion/{{sectionId}}/libreta/third">Configuración de libreta de notas 3er Bimestre</a>
      <br>
      <a routerLink="/seccion/{{sectionId}}/libreta/fourth">Configuración de libreta de notas 4to Bimestre</a>
      <br>
      <!--<a routerLink="/sections/{{sectionId}}/qualifications/1">Llenado de notas Primer Bimestre</a>-->

      <!-- <a *ngFor="let report of listOfReports$ | async" routerLink="./qualifications/{{report.id }}">
        <span>Llenado de notas: {{ report.data.description }} </span>
        <br>
      </a> -->
            
    </div>
  </mat-card-content>
</mat-card>



<ng-template #loading>
  Cargando ...
</ng-template>