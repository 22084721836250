import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Report } from '../models/report';
import { Observable } from 'rxjs/Observable';
import { Area } from '../models/area';
import { Student } from '../models/student';
import { DownloadService } from '../services/download.service';
import { Comment } from '../models/comment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.css']
})
export class ScoreComponent implements OnInit {

  reportDoc: AngularFirestoreDocument<Report>;
  report$: Observable<Report>;
  listOfAreas$: Observable<any[]>
  student$: Observable<Student>;
  comment: Comment;

  constructor(private route: ActivatedRoute,
    private db: AngularFirestore,
    private matSnackBar: MatSnackBar,
    private downloadService: DownloadService) {
  }

  ngOnInit() {
    this.comment = new Comment();
    this.getScores();
    this.getComment();
  }

  downloadFile() {
    let secId = this.route.snapshot.params['secId'];
    let repId = this.route.snapshot.params['repId'];
    let studentId = this.route.snapshot.params['studentId'];

    //console.log('download file');
    this.downloadService.getFile(secId, repId, studentId);
  }

  getComment() {

    let sectionId = this.route.snapshot.params['secId'];
    let reportId = this.route.snapshot.params['repId'];
    let studentId = this.route.snapshot.params['studentId'];

    this.db.doc<Comment>('comments/' + sectionId + '-' + reportId  + '-' + studentId)
    .valueChanges()
    .subscribe(a => {

      if (a != null)
        this.comment = a as Comment
    });

  }

  getScores() {
    let secId = this.route.snapshot.params['secId'];
    let repId = this.route.snapshot.params['repId'];
    let studentId = this.route.snapshot.params['studentId'];

    console.log('score - secId:', secId);
    console.log('score - repId:', repId);
    console.log('score - studentId:', studentId);

    // /sections/2018-2/students/7C2FCpfCSpd95dpqE78o
    this.student$ = this.db.doc<Student>('sections/' + secId + '/students/' + studentId).valueChanges()

    ///sections/2018-2/templates/first/areas
    ///sections/2018-2/templates/first/areas/kS1AhujLDYEZoabj9UJs/items
    this.reportDoc = this.db.doc<Report>('sections/' + secId + '/templates/' + repId);
    this.report$ = this.reportDoc.valueChanges();
    const areaList = [];

    this.reportDoc.collection<Area>('areas').snapshotChanges().forEach(a => {
      a.forEach(b => {
        areaList.push({
        'name': b.payload.doc.data().name,
        'order': b.payload.doc.data().order
      })
      })
    })

    console.log('areaList', areaList);

    this.listOfAreas$ = this.reportDoc.collection<Area>('areas', ref => {
      return ref.orderBy("date", "asc")
    })
    .snapshotChanges()
    .pipe(
      map(actions => {
        return actions.map(a => {
            const data = a.payload.doc.data() as Report
            const id = a.payload.doc.id;
            return { id, data }
        })
      })
    )


  }

  saveComment() {

    let sectionId = this.route.snapshot.params['secId'];
    let reportId = this.route.snapshot.params['repId'];
    let studentId = this.route.snapshot.params['studentId'];

    this.db.doc<Comment>('comments/' + sectionId + '-' + reportId  + '-' + studentId)
    .set({
      sectionId: sectionId,
      reportId: reportId,
      studentId: studentId,
      text: this.comment.text
    })
    .then(a => {
      this.matSnackBar.open('Se grabó con éxito', "Close", {
        verticalPosition: "bottom",
        duration: 1000,
      });

    })
  }

}
