<mat-toolbar color="accent">
  <mat-toolbar-row>
    <span>Lista de alumnos</span>
    <span class="example-spacer">&nbsp;</span>
    <button mat-raised-button color="primary" matTooltip="Descarga las libretas" (click)="downloadAllReports()">Descargar libretas</button>
    &nbsp;&nbsp;&nbsp;
    <button mat-raised-button color="primary" matTooltip="Abre un formulario para crear un nuevo alumno" (click)="addStudent()">Nuevo alumno</button>
  </mat-toolbar-row>
</mat-toolbar>
<div>
  <div class="student-item" *ngFor="let std of listOfStudents$ | async">
    <span class="student-name">{{std.counterValue}}. {{ std.data.fatherName }} {{ std.data.motherName }}, {{ std.data.name }}</span>
    <div class="student-space"> </div>
    <i class="" matTooltip="Completar libreta Primer bimestre" class="material-icons report-1" (click)="goToReport(std.id, 'first');">looks_one</i>
    <i class="report-2"  matTooltip="Completar libreta Segundo bimestre" class="material-icons report-2" (click)="goToReport(std.id, 'second');">looks_two</i>
    <i class="report-3"  matTooltip="Completar libreta Tercer bimestre" class="material-icons report-3" (click)="goToReport(std.id, 'third');">looks_3</i>
    <i class="report-4"  matTooltip="Completar libreta Cuarto bimestre" class="material-icons report-4" (click)="goToReport(std.id, 'fourth');">looks_4</i>

    <i class="download-1"  matTooltip="Descargar libreta Primer bimestre" class="material-icons download-1" (click)="downloadReport(std.id, 'first');">filter_1</i>
    <i class="download-2"  matTooltip="Descargar libreta Segundo bimestre" class="material-icons download-2" (click)="downloadReport(std.id, 'second');">filter_2</i>
    <i class="download-3"  matTooltip="Descargar libreta Tercer bimestre" class="material-icons download-3" (click)="downloadReport(std.id, 'third');">filter_3</i>
    <i class="download-4"  matTooltip="Descargar libreta Cuarto bimestre" class="material-icons download-4" (click)="downloadReport(std.id, 'fourth');">filter_4</i>


    <mat-icon class="action-1" matTooltip="Editar datos del alumno" style="cursor: pointer" (click)="edit(std.id);">edit</mat-icon>
    <mat-icon class="action-2" matTooltip="Borrar alumno" style="cursor: pointer" (click)="delete(std.id);">delete</mat-icon>
  </div>
</div>
<!-- <mat-nav-list dense>
  <mat-list-item *ngFor="let std of listOfStudents$ | async">
    <span matListItemTitle>{{std.counterValue}}. {{ std.data.fatherName }} {{ std.data.motherName }}, {{ std.data.name }}</span>
    <span class="example-spacer"></span>
    <i matTooltip="Completar libreta Primer bimestre" class="material-icons" (click)="goToReport(std.id, 'first');">looks_one</i>

    <i  matTooltip="Completar libreta Segundo bimestre" class="material-icons" (click)="goToReport(std.id, 'second');">looks_two</i>
    <i  matTooltip="Completar libreta Tercer bimestre" class="material-icons" (click)="goToReport(std.id, 'third');">looks_3</i>
    <i  matTooltip="Completar libreta Cuarto bimestre" class="material-icons" (click)="goToReport(std.id, 'fourth');">looks_4</i>

    <i  matTooltip="Descargar libreta Primer bimestre" class="material-icons" (click)="downloadReport(std.id, 'first');">filter_1</i>
    <i  matTooltip="Descargar libreta Segundo bimestre" class="material-icons" (click)="downloadReport(std.id, 'second');">filter_2</i>
    <i  matTooltip="Descargar libreta Tercer bimestre" class="material-icons" (click)="downloadReport(std.id, 'third');">filter_3</i>
    <i  matTooltip="Descargar libreta Cuarto bimestre" class="material-icons" (click)="downloadReport(std.id, 'fourth');">filter_4</i>

    <mat-icon matTooltip="Editar datos del alumno" class="example-icon" style="cursor: pointer" (click)="edit(std.id);">edit</mat-icon>
    <mat-icon matTooltip="Borrar alumno" class="example-icon" style="cursor: pointer" (click)="delete(std.id);">delete</mat-icon>
    <mat-divider></mat-divider>
  </mat-list-item>
</mat-nav-list> -->
<!-- <mat-toolbar>
  <mat-toolbar-row *ngFor="let std of listOfStudents$ | async">
    <span style="font-size:11pt;">{{ std.counterValue}}. {{ std.data.fatherName }} {{ std.data.motherName }}, {{ std.data.name }}</span>
    <span class="example-spacer"></span>
    <mat-icon matTooltip="Completar libreta" class="example-icon" style="cursor: pointer" (click)="goToReport(std.id);">file_copy</mat-icon>
    <mat-icon matTooltip="Descargar libreta" class="example-icon" style="cursor: pointer" (click)="downloadReport(std.id);">cloud_download</mat-icon>
    <mat-icon matTooltip="Editar datos del alumno" class="example-icon" style="cursor: pointer" (click)="edit(std.id);">edit</mat-icon>
    <mat-icon matTooltip="Borrar alumno" class="example-icon" style="cursor: pointer" (click)="delete(std.id);">delete</mat-icon>
    <mat-divider></mat-divider>
  </mat-toolbar-row>
</mat-toolbar> -->

<ng-template #loading>
  Cargando ...
</ng-template>
