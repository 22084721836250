import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Student } from '../models/student';
import { Observable } from "rxjs/Observable";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DownloadService {

    constructor(private db: AngularFirestore, private http: HttpClient) {
        // const settings = { timestampsInSnapshots: true};
        // db.firestore.settings(settings);
    }

    /**Go to azure website to download a file */
    getFile(sectionId, reportId, studentId) {
        let parameters = '?sectionId=' + sectionId + '&reportId=' + reportId + '&studentId=' + studentId;
        let azureUrl = 'https://rosymarti.azurewebsites.net/DocumentFactory/DescargarArchivo' + parameters;

        window.open(azureUrl);

        // var headers = new HttpHeaders();
        // headers.append('Access-Control-Allow-Origin', '*');

        // this.http.get(azureUrl, { headers: headers })
        // .toPromise()
        // .then(res => {
        //      console.log(res);
        //  })
        //  .catch(err => {
        //      console.log(err);
        //  })
    }

    getAllFilesBySectionId(sectionId: string, reportId: string) {
      let parameters = `?sectionId=${sectionId}&reportId=${reportId}`;
      let azureUrl = `https://rosymarti.azurewebsites.net/DocumentFactory/DescargarLibretasPorSeccion${parameters}`;
      //let azureUrl = `http://localhost:30965/DocumentFactory/DescargarLibretasPorSeccion${parameters}`;

      //console.log(azureUrl);
      window.open(azureUrl);
    }
}
