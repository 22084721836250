import { Component, OnInit, Input } from '@angular/core';
import { Area } from '../models/area';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs/Observable';
import { AreaItem } from '../models/area-item';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaItemFormComponent } from '../area-item-form/area-item-form.component';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-area-item',
  templateUrl: './area-item.component.html',
  styleUrls: ['./area-item.component.css']
})
export class AreaItemComponent implements OnInit {

  //it contains the item of each area
  @Input() areaId: string; //it receives the information from
  areaDoc: AngularFirestoreDocument<Area>;
  listOfItems$: Observable<any[]>

  constructor(private route: ActivatedRoute, private db: AngularFirestore,
    private matDialog: MatDialog) { }

  ngOnInit() {
    this.showItems();
  }

  showItems() {
    let reportId = this.route.snapshot.params['repId'];
    let secId = this.route.snapshot.params['secId'];

    this.areaDoc = this.db.doc<Area>('sections/' + secId + '/templates/' + reportId + '/areas/' + this.areaId);

    this.listOfItems$ = this.areaDoc.collection<AreaItem>('items', ref => {
      return ref.orderBy("date", "asc")
    }).snapshotChanges()
    .pipe(
      map(actions => {
        return actions.map(a => {
            const data = a.payload.doc.data() as AreaItem
            const id = a.payload.doc.id;
            return { id, data }
        })
      })
    )

  }

  updateItem(areaItemId) {

    let reportId = this.route.snapshot.params['repId'];
    let secId = this.route.snapshot.params['secId'];

    let dialogRef = this.matDialog.open(AreaItemFormComponent, {
      width: '600px',
      data: { reportId: reportId, areaId: this.areaId, sectionId: secId, areaItemId: areaItemId}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("after closed event");
    });

  }

  deleteItem(areaItemId) {

    let reportId = this.route.snapshot.params['repId'];
    let secId = this.route.snapshot.params['secId'];

    if (confirm('¿Está seguro de eliminar el item?'))
    {
      this.db.doc<AreaItem>('sections/' + secId + '/templates/' + reportId + '/areas/' + this.areaId + '/items/' + areaItemId)
      .delete()
      .then(a => {
        alert('El item se eliminó con éxito');
      })
    }
  }

}
