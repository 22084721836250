import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
// import * as firebase from 'firebase';
import { Student } from '../models/student';
import { Observable } from 'rxjs/Observable';
import { Section } from '../models/section';
// import { DocumentReference } from '@firebase/firestore-types';
import { StudentService } from '../services/student.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewStudentComponent } from '../new-student/new-student.component';
import { DownloadService } from '../services/download.service';
import { map } from 'rxjs/operators';
import { SelectReportComponent } from '../ui/reports/select-report/select-report.component';


@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})
export class StudentComponent implements OnInit {

  sectionId: string;
  student: Student;
  sectionDoc: AngularFirestoreDocument<Section>;
  section$: Observable<Section>;
  //sectionRef: DocumentReference;
  private studentCollection: AngularFirestoreCollection<Student>;
  listOfStudents$: Observable<any[]>

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private studentService: StudentService,
    private matDialog: MatDialog,
    private downloadService: DownloadService,
    private router: Router) {
    this.student = new Student();

  }

  ngOnInit() {
    this.getStudents();
  }

  onSubmit() {

    this.sectionDoc.collection<Student>('students').add(
      {
        name: this.student.name,
        fatherName: this.student.fatherName,
        motherName: this.student.motherName
      }
    );
  }

  goTo() {
    alert('hey');
  }

  getStudents() {
    this.route.paramMap.subscribe(p => {

      this.sectionId = p.get('secId');

      this.sectionDoc = this.db.doc<Section>('sections/' + this.sectionId);

      //this.sectionRef = this.sectionDoc.ref;
      //this.section$ = this.sectionDoc.valueChanges();

      //this.studentCollection = this.studentService.getBySection(this.sectionRef);


      this.listOfStudents$ = this.sectionDoc.collection<Student>('students', ref => {
        return ref.orderBy("fatherName", "asc")
      })
        .snapshotChanges()
        .pipe(
          map(actions => {
            let counter = 1;
            return actions.map(a => {
              const data = a.payload.doc.data() as Student
              const id = a.payload.doc.id;
              const counterValue = counter++;
              return { id, data, counterValue }
            })
          })
        )


    });
  }

  addStudent() {

    let dialogRef = this.matDialog.open(NewStudentComponent, {
      width: '600px',
      data: { sectionId: this.sectionId, studentId: '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("new student window closed");
    });
  }

  edit(studentId) {

    let dialogRef = this.matDialog.open(NewStudentComponent, {
      width: '600px',
      data: { sectionId: this.sectionId, studentId: studentId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("this is always showed");
    });
  }

  delete(studentId) {

    if (confirm('¿Está seguro de eliminar al alumno?')) {
      this.db.doc<Student>('sections/' + this.sectionId + '/students/' + studentId).delete();
    }
  }

  /**Open the web form to complete the report. */
  goToReport(studentId, repId) {
    this.router.navigate([ 'seccion/' + this.sectionId + '/libreta/' + repId + '/notas/' + studentId]);
  }

  /**Download in word format the information about califications */
  downloadReport(studentId, repId) {

    let secId = this.route.snapshot.params['secId'];
    this.downloadService.getFile(secId, repId, studentId);
  }

  optionSelected () {
    console.log('hey');
  }

  downloadAllReports() {

    const dialogRef = this.matDialog.open(SelectReportComponent, {
      width: '500px',
      data: { sectionId: this.sectionId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("new student window closed");
    });
  }
}
