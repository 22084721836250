<div class="container">
<h6>Formulario de alumno</h6>

<div *ngIf="student">
  <mat-form-field style="width:100%" floatLabel="always">
    <input matInput value="" placeholder="Nombres" [(ngModel)]="student.name" name="name">
  </mat-form-field>

  <mat-form-field style="width:100%" floatLabel="always">
    <input matInput value="" placeholder="A. Paterno" [(ngModel)]="student.fatherName" name="fatherName">
  </mat-form-field>

  <mat-form-field style="width:100%" floatLabel="always">
    <input matInput value="" placeholder="A. Materno" [(ngModel)]="student.motherName" name="motherName">
  </mat-form-field>

  <div style="text-align: right">
      <button mat-button color="primary" (click)="close()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="save()">Guardar</button>
  </div>
</div>

<ng-template #loading>
  Cargando ...
</ng-template>

</div>
