import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Student } from '../models/student';
import { Observable } from "rxjs/Observable";

@Injectable()
export class StudentService {

    private studentsCollection: AngularFirestoreCollection<Student>;
    listOfStudents: Observable<Student[]>;

    constructor(private db: AngularFirestore) {
        // const settings = { timestampsInSnapshots: true};
        // db.firestore.settings(settings);


    }

    /*Get all sections filtered by year */
    getBySection(sectionRef) {

        return this.studentsCollection = this.db.collection<Student>('students', ref => {
            return ref.where("sectionRef", "==", sectionRef)
          });
    }
}
