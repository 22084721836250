import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Section } from '../models/section';
import { Observable } from 'rxjs/Observable';
import { Student } from '../models/student';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.css']
})
export class QualificationComponent implements OnInit {

  listOfStudents$: Observable<any[]>

  constructor(private route: ActivatedRoute, private db: AngularFirestore) { }

  ngOnInit() {
    this.loadStudents();
  }

  loadStudents() {
    let secId = this.route.snapshot.params['secId'];
    //let periodNumber = this.route.snapshot.params['periodNumber'];

    this.listOfStudents$ = this.db.collection<Student>('sections/' + secId + '/students', ref => {
      return ref.orderBy("name", "asc")
    })
    .snapshotChanges()
    .pipe(
      map(actions => {
        return actions.map(a => {
            const data = a.payload.doc.data() as Student
            const id = a.payload.doc.id;
            return { id, data }
        })
      })
    )



  }
}
