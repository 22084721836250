import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirmacion-envio',
  templateUrl: './confirmacion-envio.component.html',
  styleUrls: ['./confirmacion-envio.component.css']
})
export class ConfirmacionEnvioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
