import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { TeacherAssitControl } from './teacher/teacher-assist-control.component';
import { MaterialModule } from "../material.module";

export const ROUTES: Routes = [
    { path: 'teacher-assist', component: TeacherAssitControl}
]

@NgModule({
    declarations: [
        TeacherAssitControl
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule.forChild(ROUTES)
    ]

})
export class AssistControlModule {}