<mat-toolbar color="accent">
  <mat-toolbar-row>
    <span *ngIf="(report$ | async) as report; else loading" style="font-size:10pt">
      <i class="material-icons">
        {{ report.icon }}
      </i>
      {{ report.name }}:
      <span *ngIf="(student$ | async) as student; else loading" style="font-size:10pt">
        {{ student.fatherName }} {{ student.motherName }}, {{ student.name }}
      </span>
    </span>
    <span class="example-spacer"></span>
    <button mat-raised-button color="primary" (click)="downloadFile()">Descargar libreta en Word</button>
  </mat-toolbar-row>
</mat-toolbar>

<mat-card>
  <mat-card-content>
    <h2>Áreas</h2>
    <div *ngFor="let area of listOfAreas$ | async">
      <strong>{{ area.data.name }}</strong>
      <app-score-item [areaId]="area.id"></app-score-item>
    </div>
    <!--Comments-->
    <strong>Observaciones</strong>
    <br>
    <textarea style="width: 400px;" cols="10" rows="10" [(ngModel)]="comment.text" name="text" (blur)="saveComment()"></textarea>
  </mat-card-content>
</mat-card>

<ng-template #loading>
  Cargando...
</ng-template>