<form #myForm="ngForm" (ngSubmit)="enviar()">
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Correo electrónico</mat-label>
    <input  #myemail="ngModel" matInput placeholder="" type="email" value="" name="emailRestablecer" [(ngModel)]="emailRestablecer" required>
    <div *ngIf="myemail.errors?.required && myemail.touched" class="error">
      El correo es obligatorio
    </div>
  </mat-form-field>
  <div>
    <button mat-raised-button color="primary" type="submit" [disabled]="!myForm.valid" >Enviar link para restablecer contraseña</button>
    <div class="error" *ngIf="error">
      {{ error }}
    </div>
    <div *ngIf="exito" style="margin-top: 20px;">
      Correo enviado con éxito. Ingresar al correo para restablecer la contraseña.
    </div>
  </div>

</form>
