import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

import { MaterialModule } from './material.module';
import { environment } from '../environments/environment';
import { SectionService } from './services/section.service';
import { AppRoutingModule } from './/app-routing.module';
import { SectionComponent } from './section/section.component';
import { StudentComponent } from './student/student.component';
import { StudentService } from './services/student.service';
import { ReportComponent } from './report/report.component';
import { ReportDetailComponent } from './report-detail/report-detail.component';
import { AreaItemComponent } from './area-item/area-item.component';
import { QualificationComponent } from './qualification/qualification.component';
import { ScoreComponent } from './score/score.component';
import { ScoreItemComponent } from './score-item/score-item.component';
import { ScoreValueComponent } from './score-value/score-value.component';
import { DownloadComponent } from './download/download.component';
import { DownloadService } from './services/download.service';
import { NewStudentComponent } from './new-student/new-student.component';
import { AreaFormComponent } from './area-form/area-form.component';
import { AreaItemFormComponent } from './area-item-form/area-item-form.component';
import { CommentComponent } from './comment/comment.component';
import { AuthService } from './services/auth.service';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { MigrationService } from './services/migration.service';
import { AssistControlModule } from './assist-control/assit-control.module';
import { StaffService } from './services/staff.service';
import { RestablecerContrasenaComponent } from './restablecer-contrasena/restablecer-contrasena.component';
import { ConfirmacionEnvioComponent } from './confirmacion-envio/confirmacion-envio.component';
import { SelectReportComponent } from './ui/reports/select-report/select-report.component';

@NgModule({
  declarations: [
    AppComponent,
    SectionComponent,
    StudentComponent,
    ReportComponent,
    ReportDetailComponent,
    AreaItemComponent,
    QualificationComponent,
    ScoreComponent,
    ScoreItemComponent,
    ScoreValueComponent,
    NewStudentComponent,
    AreaFormComponent,
    AreaItemFormComponent,
    DownloadComponent,
    CommentComponent,
    RestablecerContrasenaComponent,
    ConfirmacionEnvioComponent,
    SelectReportComponent
  ],
  // entryComponents: [
  //   NewStudentComponent, AreaFormComponent, AreaItemFormComponent, RestablecerContrasenaComponent
  // ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule,
    MaterialModule,
    AppRoutingModule,
    AssistControlModule
  ],
  providers: [
    SectionService,
    StudentService,
    DownloadService,
    AuthService,
    MigrationService,
    StaffService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
