import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/compat/firestore';
import { Section } from '../models/section';
import { Observable } from 'rxjs/Observable';
import { Report } from '../models/report';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit {

  constructor( private route: ActivatedRoute, private db: AngularFirestore) { }
  sectionId: string;
  sectionDoc: AngularFirestoreDocument<Section>;
  section$ : Observable<Section>;
  section: Section;
  listOfReports$: Observable<any[]>

  ngOnInit() {

    this.getSection();
  }

  getSection()
  {
    this.route.paramMap.subscribe(p => {
      this.sectionId = p.get('secId');

      this.sectionDoc = this.db.doc<Section>('sections/' + this.sectionId);
      this.sectionDoc.valueChanges()
      .subscribe(res => {
        this.section = res;
        document.getElementById('spanTitle').innerHTML = 'Sección ' + this.section.name;
      })

      this.listOfReports$ = this.sectionDoc.collection<Report>('reports', ref => {
        return ref.orderBy("order", "asc")
      })
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
              const data = a.payload.doc.data() as Report
              const id = a.payload.doc.id;
              return { id, data }
            })
        })
      )


    });
  }

}
