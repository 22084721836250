
<div class="container">
<h5>Descarga masiva de libretas de la sección</h5>
<label>Seleccione una opción y de clic en 'Descargar' para generar un archivo ZIP con las libretas en formato Word</label>

<mat-radio-group class="example-radio-group" aria-labelledby="radio-group-label" [(ngModel)]="reportId">
  <mat-radio-button class="example-radio-button" value="first">Primer Bimestre</mat-radio-button>
  <mat-radio-button class="example-radio-button" value="second">Segundo Bimestre</mat-radio-button>
  <mat-radio-button class="example-radio-button" value="third">Tercer Bimestre</mat-radio-button>
  <mat-radio-button class="example-radio-button" value="fourth">Cuarto Bimestre</mat-radio-button>
</mat-radio-group>

<div style="text-align: right">
  <button mat-button color="primary" (click)="close()">Cancelar</button>
  <button mat-raised-button color="primary" (click)="select()">Descargar</button>
</div>

</div>
