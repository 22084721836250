import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SectionComponent } from './section/section.component';
import { StudentComponent } from './student/student.component';
import { ReportComponent } from './report/report.component';
import { ReportDetailComponent } from './report-detail/report-detail.component';
import { QualificationComponent } from './qualification/qualification.component';
import { ScoreComponent } from './score/score.component';
import { DownloadComponent } from './download/download.component';
import { TeacherAssitControl } from './assist-control/teacher/teacher-assist-control.component';
import { RestablecerContrasenaComponent } from './restablecer-contrasena/restablecer-contrasena.component';

const routes: Routes = [
  { path: 'seccion/:secId', component: SectionComponent },
  { path: 'seccion/:secId/alumnos', component: StudentComponent },
  { path: 'seccion/:secId/libreta', component: ReportComponent },
  { path: 'seccion/:secId/libreta/:repId', component: ReportDetailComponent },
  { path: 'seccion/:secId/qualifications/:repId', component: QualificationComponent },
  { path: 'seccion/:secId/libreta/:repId/notas/:studentId', component: ScoreComponent },
  { path: 'documents', component: DownloadComponent },
  // { path: "restablecer", component: RestablecerContrasenaComponent }
  // { path: 'teacher-assist', component: TeacherAssitControl },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
