import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute } from '@angular/router';
import { AreaItem } from '../models/area-item';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-area-item-form',
  templateUrl: './area-item-form.component.html',
  styleUrls: ['./area-item-form.component.css']
})
export class AreaItemFormComponent implements OnInit {

  reportId: string = '';
  areaId: string = '';
  areaItemId: string = '';
  areaItem: AreaItem;
  sectionId: string = '';

  constructor(
    public dialogRef: MatDialogRef<AreaItemFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private db: AngularFirestore, private auth: AngularFireAuth, private authService: AuthService
  ) {
    this.reportId = data.reportId; //this is the name of the report (first, second, third, fourth)
    this.areaId = data.areaId;
    this.sectionId = data.sectionId;
    this.areaItemId = data.areaItemId;

  }

  ngOnInit() {
    console.log('areaitemform');
    if (this.areaItemId == '') {
      this.areaItem = new AreaItem();
    }
    else {
      ///sections/2018-2/templates/first/areas/6fbxQv8YTURRsuiqzDwb
      this.db.doc<AreaItem>('sections/' + this.sectionId + '/templates/' + this.reportId + '/areas/' + this.areaId + '/items/' + this.areaItemId)
        .valueChanges()
        .subscribe(a => {
          this.areaItem = a;
        });
    }
  }

  save() {


    if (this.areaItemId == '') {
      console.log('sectionId', this.sectionId);
      console.log('reportId', this.reportId);

      //we need to know how many areas are already created:
      var areaItemCollection = this.db.collection<AreaItem>('sections/' + this.sectionId + '/templates/' + this.reportId + '/areas/' + this.areaId + '/items');

      areaItemCollection.ref.get().then(a => {

        areaItemCollection.add({
          name: this.areaItem.name,
          order: a.size + 1,
          date: new Date(),
          createdBy: this.authService.emailUser,
          modifiedBy: ''
        })
          .then(a => {
          })
      });
    }
    else {
      console.log('update item');
      this.db.doc<AreaItem>('sections/' + this.sectionId + '/templates/' + this.reportId + '/areas/' + this.areaId + '/items/' + this.areaItemId)
        .update({
          name: this.areaItem.name,
          modifiedBy: this.authService.emailUser,
        })
        .then(a => {
        });

    }

    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(true);
  }

}
