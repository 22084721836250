import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { ScoreValue } from '../models/score-value';
import { Observable } from 'rxjs/Observable';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-score-value',
  templateUrl: './score-value.component.html',
  styleUrls: ['./score-value.component.css']
})
export class ScoreValueComponent implements OnInit {

  @Input() itemId: string;
  scoreValueDoc: AngularFirestoreDocument<ScoreValue>;
  scoreValue$ : Observable<ScoreValue>;

  value: string;
  constructor(private route: ActivatedRoute,
    private db: AngularFirestore,
    private matSnackBar: MatSnackBar) {
    this.value = "";
  }

  ngOnInit() {
    this.loadValue();
  }

  saveValue() {

    let studentId = this.route.snapshot.params['studentId'];
    this.db.collection<ScoreValue>('scoreValues').doc(this.itemId + '-' + studentId).set({
      itemId: this.itemId,
      studentId: studentId,
      value: this.value
    })
    .then(a => {
      console.log('then', a);

      this.matSnackBar.open('Se grabó con éxito', "Close", {
        verticalPosition: "bottom",
        duration: 1000,
      });
    })
    .catch(err => {
      console.log('err', err);
    });
  }

  loadValue()
  {

    let studentId = this.route.snapshot.params['studentId'];
    //console.log('itemId', this.itemId);
    //console.log('studentId', studentId);

    this.scoreValueDoc = this.db.doc<ScoreValue>('scoreValues/' + this.itemId + '-' + studentId);
    //console.log(this.scoreValueDoc);

    this.scoreValueDoc.valueChanges()
    .subscribe(a => {
      if (a != null)
        this.value = a.value;


    });

    // .snapshotChanges()
    // .map(actions => {
    //   if (actions.payload.exists)
    //   {
    //     console.log('sí existe');
    //     this.value = actions.payload.get('value');
    //   }
    //   else
    //   {
    //     console.log('no existe');
    //   }

    // })


  }
}
