
<br>
<br>
<h4>Lista de bimestres:</h4>
<div class="row">
  <div class="col-md-4">
    <!-- <a *ngFor="let report of listOfReports$ | async" (click)="onSelect(report.data)"> -->
    <a *ngFor="let report of listOfReports$ | async" routerLink="./{{report.id }}">    
      <span>{{ report.data.name }} </span>
      <br>
    </a>
  </div>
</div>
<!-- <app-report-detail [report]="selectedReport"></app-report-detail> -->

<ng-template #loading>
  Loading stuff...
</ng-template>