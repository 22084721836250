import { Component, OnInit, Input } from '@angular/core';
import { Area } from '../models/area';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs/Observable';
import { AreaItem } from '../models/area-item';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-score-item',
  templateUrl: './score-item.component.html',
  styleUrls: ['./score-item.component.css']
})
export class ScoreItemComponent implements OnInit {

  @Input() areaId: string;
  areaDoc: AngularFirestoreDocument<Area>;
  listOfItems$: Observable<any[]>

  constructor(private route: ActivatedRoute, private db: AngularFirestore) { }

  ngOnInit() {
    this.showItems();
  }

  showItems() {
    let reportId = this.route.snapshot.params['repId'];
    let secId = this.route.snapshot.params['secId'];

    this.areaDoc = this.db.doc<Area>('sections/' + secId + '/templates/' + reportId + '/areas/' + this.areaId);

    this.listOfItems$ = this.areaDoc.collection<AreaItem>('items', ref => {
      return ref.orderBy("date", "asc")
    })
    .snapshotChanges()
    .pipe(
      map(actions => {
        return actions.map(a => {
            const data = a.payload.doc.data() as AreaItem
            const id = a.payload.doc.id;
            return { id, data }
        })
      })
    )


  }
}
