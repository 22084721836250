import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Area } from '../models/area';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-area-form',
  templateUrl: './area-form.component.html',
  styleUrls: ['./area-form.component.css']
})
export class AreaFormComponent implements OnInit {

  reportId: string = '';
  areaId: string = '';
  area: Area;
  sectionId: string = '';

  //form to create or update an area
  constructor(
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<AreaFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private db: AngularFirestore,
    private auth: AngularFireAuth,
    private authService: AuthService
  ) {
    this.reportId = data.reportId; //this is the name of the report (first, second, third, fourth)
    this.areaId = data.areaId;
    this.sectionId = data.sectionId;

  }

  ngOnInit() {
    if (this.areaId == '')
    {
      this.area = new Area();
    }
    else
    {
      ///sections/2018-2/templates/first/areas/6fbxQv8YTURRsuiqzDwb
      this.db.doc<Area>('sections/' + this.sectionId + '/templates/' + this.reportId + '/areas/' + this.areaId)
      .valueChanges()
      .subscribe(a => {
        this.area = a;
      });
    }
  }

  save() {


    if (this.areaId == '') {
      console.log('sectionId', this.sectionId);
      console.log('reportId', this.reportId);

      //we need to know how many areas are already created:
      var areaCollection = this.db.collection<Area>('sections/' + this.sectionId + '/templates/' + this.reportId + '/areas');

      console.log(areaCollection.doc.length);

      areaCollection.ref.get().then(a => {
        console.log(a.size )

        areaCollection.add({
          name: this.area.name,
          order: a.size + 1,
          date: new Date(),
          createdBy: this.authService.emailUser,
          modifiedBy: ''
        })
        .then(a => {

        })
      });

    }
    else {
      console.log('update area');
      this.db.doc<Area>('sections/' + this.sectionId + '/templates/' + this.reportId + '/areas/' + this.areaId)
        .update({
          name: this.area.name,
          modifiedBy: this.authService.emailUser,
        })
        .then(a => {
          //this.dialogRef.close(true);
        });

    }

    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(true);
  }

}
