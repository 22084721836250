<div class="container">
<h6>Formulario de área</h6>

<div *ngIf="area">
  <mat-form-field style="width:100%" floatLabel="always">
    <input matInput value="" placeholder="Area" [(ngModel)]="area.name" name="name">
  </mat-form-field>

  <div style="text-align: right">
    <button mat-button color="primary" (click)="close()">Cancelar</button>

    <button mat-raised-button color="primary" (click)="save()">Guardar</button>
  </div>
</div>

</div>
