import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/auth';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthService {
  user: Observable<firebase.User>;
  emailUser: string = '';

  constructor(private firebaseAuth: AngularFireAuth) {
    this.user = firebaseAuth.authState;

  }

  signup(email: string, password: string) {
    this.firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then(value => {
        console.log('Success!', value);
      })
      .catch(err => {
        console.log('Something went wrong:',err.message);
      });
  }

  // login(email: string, password: string) {
  //   this.firebaseAuth
  //     .signInWithEmailAndPassword(email, password)
  //     .then(value => {

  //       this.emailUser = email;
  //       console.log('Nice, it worked!');
  //     })
  //     .catch(err => {
  //       console.log('Something went wrong:',err.message);
  //     });
  // }

  /**
   *
   * @param email
   * @param password
   * @returns Returns a promise
   */

  login(email: string, password: string) {
    return this.firebaseAuth
      .signInWithEmailAndPassword(email, password);
  }

  logout() {
    this.firebaseAuth
      .signOut();
  }

  enviarLink(email: string) {
    return this.firebaseAuth.sendPasswordResetEmail(email);
  }
}
