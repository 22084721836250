import { Component, OnInit } from "@angular/core";
import { StaffService } from '../../services/staff.service';
import { Observable } from "rxjs/Observable";
import { Staff } from '../../models/staff';

@Component({
    selector: 'teacher-assist-control',
    templateUrl: './teacher-assist-control.component.html',
    styleUrls: ['./teacher-assist-control.component.css']
})
export class TeacherAssitControl implements OnInit {

    public now: Date = new Date();
    staffList$: any;
    staff$: Observable<any>;
    fullName: string;
    selectedDNI: string;
    assistList$: Observable<any>;

    constructor(
        private staffService: StaffService
    ) {
        setInterval(() => {
            this.now = new Date();
        }, 1);
    }

    ngOnInit(): void {
        this.loadStaff();
        this.loadAssistList();
    }


    loadStaff() {
        this.staffList$ = this.staffService.getStaff();
    }

    loadAssistList() {
        this.assistList$ = this.staffService.getAssistList();
    }

    searchPerson(dni: string) {
        console.log(dni);

        this.staffService.getByDNI(dni)
            .subscribe(a => {

                let staff = a as Staff;
                this.fullName = staff.name + " " + staff.lastName;

                this.selectedDNI = dni;

            });

    }

    clean() {
        this.selectedDNI = "";
        this.fullName = "";
        (<HTMLInputElement>document.getElementById('txtDNI')).value = '';
    }

    saveAssist(type: string) {

        if (this.selectedDNI == "" || this.selectedDNI == null)
        {
            alert('Debe ingresar un DNI para continuar.');
            return;
        }

        let typeText = type == 'i' ? 'INGRESO': 'SALIDA';
        if (confirm('¿Confirma el ' + typeText + '? ')) {
            this.staffService.saveAssit(this.selectedDNI, this.fullName, type)
                .then(a => {

                    this.clean();
                    alert('Se registró la asistencia con éxito');
                });
        }
    }
}