import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DownloadService } from '../../../services/download.service';

@Component({
  selector: 'app-select-report',
  templateUrl: './select-report.component.html',
  styleUrl: './select-report.component.css'
})
export class SelectReportComponent {

  reportId: string = '';

  constructor(
    public dialogRef: MatDialogRef<SelectReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private downloadService: DownloadService
  ) {
  }

  select() {

    console.log('sectionId:', this.data.sectionId);
    console.log('reportId:', this.reportId);

    this.downloadService.getAllFilesBySectionId(this.data.sectionId, this.reportId);
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(true);
  }
}
